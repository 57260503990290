html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ultra-lg-modal {
  width: 95%;
  max-width: none;
}

.card-thumbnails-left {
  width: 1var(--dot-flashing-radius);
  height: 130px;
}

.key-value-table th {
  width: 200px;
}

td.min,
th.min {
  width: 1% !important;
  white-space: nowrap !important;
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 :root {
  --dot-flashing-radius: 7px;
}

 .dot-flashing {
  position: relative;
  width: var(--dot-flashing-radius);
  height: var(--dot-flashing-radius);
  border-radius: 5px;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -12px;
  width: var(--dot-flashing-radius);
  height: var(--dot-flashing-radius);
  border-radius: 5px;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 12px;
  width: var(--dot-flashing-radius);
  height: var(--dot-flashing-radius);
  border-radius: 5px;
  background-color: var(--bs-primary);
  color: var(--bs-primary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--bs-primary);
  }
  50%, 100% {
    background-color: var(--bs-blue-100);
  }
}