.bg-cataleeze {
  background-color: #0D6ABC;
}

iframe {
  border: none;
}

#report-container > div {
  height: calc(40vw);
}


@media only screen and (max-width: 574px) {
  #report-container {
    height: calc(40vw);
  }
}

#report-container {
  height: calc(40vw);
}

.header {
  min-height: 4rem;
}

aside {
  position: sticky !important;
  max-height: calc(100vh - 4rem);
  top: 4rem;
  z-index: 1000;
  overflow-y: auto;
}