/*version scss**/
/* .json { */
    /* background: #f2f2f2; */
    /* padding: 2rem; */
    /* margin: 2rem; */
 /*   first line not indented*/
    /* & > .line { */
      /* margin-left: 0; */
    /* } */
    /* .line { */
      /* margin-left: 1rem; */
    /* } */
    /* .key { */
      /* margin-right: 0.5rem; */
      /* color: black; */
      /* font-weight: 600; */
    /* } */
    /* .string { */
      /* color: green; */
    /* } */
    /* .number { */
      /* color: blue; */
    /* } */
    /* .boolean { */
      /* color: purple; */
    /* } */
    /* .null { */
      /* color: red; */
    /* } */
  /* } */
  
  .json {
    outline: 1px solid #ccc;
    background: #f2f2f2 ;
    padding-top: 0.5rem  ;
    padding-bottom: 0.5rem  ;
    padding-left: 0.5rem  ;
    /* margin: 1rem; 
    /* first line not indented */
  }
  .json > .line {
    margin-left: 0;
  }
  .json .line {
    margin-left: 0.5rem;
  }
  .json .key {
    margin-right: 0.25rem;
    color: black;
    font-weight: bold;
  }
  .json .string {
    color: green;
  }
  .json .number {
    color: blue;
  }
  .json .boolean {
    color: purple;
  }
  .json .null {
    color: red;
  }