.key-value-table th {
    width: 200px;
}

.order-line pre {
    font-size: 1.1em;
    margin-bottom: 0;
}

.order-line .line-label {
    display: block;
    /* font-size: 1.1em; */
}